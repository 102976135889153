import { SalesDataType } from '@crema/types/models/extrapages/Portfolio';

export const salesData: SalesDataType[] = [
  {
    id: 2001,
    ques: 'How much one could earn using your products?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
  {
    id: 2002,
    ques: 'What are some common taxes applied to these products?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
  {
    id: 2003,
    ques: 'What is the commission structues you are offering to affiliates?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
  {
    id: 2004,
    ques: 'How much incentive do you pay if someone reaches the sale goal?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
  {
    id: 2005,
    ques: 'How much one could earn using your products?',
    ans: "orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries.",
    tags: ['SALES', 'EARNING', 'PRE-SALES'],
  },
];
